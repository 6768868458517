import React from 'react';

const GatewayRadio = () => {
    return (
        <div>
            Gateway Jubilee Radio
        </div>
    );
}

export default GatewayRadio;
