import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import youtubeLiveBtn from '../../images/youtube-live-btn.png';
import facebookLiveBtn from '../../images/facebook-live-btn.png';

const Livestream = () => {

    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        <>
            {/* <div id="dark-blue-bg">
                <div className="container page-hero" id="live-hero">
                    <div className="page-hero-message">
                        <h1>Gateway Live</h1>
                    </div>
                </div>
            </div> */}

            <section className="light-bg">
                <br /><br /><br /><br />
                <div className="container-narrow padding-50-20">
                    <div className="flex flex-center">

                        <div className="width-50 padding-bottom-20-s">
                            <h2 className="center-text margin-bottom-20">Streaming Live Each Week</h2>
                            <a href="https://www.youtube.com/channel/UC55s_bq1UXTe54uajXNV5kQ" target="_blank" rel="noreferrer" className="max-width-400 margin-bottom-5">
                                <img src={youtubeLiveBtn} alt="youtube button"/>
                            </a>

                            <a href="https://www.facebook.com/gatewaybcsc/" target="_blank" rel="noreferrer" className="max-width-400 margin-bottom-5">
                                <img src={facebookLiveBtn} alt="facebook button"/>
                            </a>
                        </div>

                        <div className="width-50 flex flex-center flex-column">
                            <div>
                                <h4 className="padding-bottom-10">Streaming Schedule:</h4>
                                <p>Sunday School: 10:00am</p>
                                <p>Sunday Morning Worship: 11:00am</p>
                                <p>Sunday Evening Worship: 6:30pm</p>
                                <p>Wednesday Prayer Meeting: 7:00pm</p>
                                {/* <br /><br />
                                <h4 className="padding-bottom-10">Jubilee Schedule:</h4>
                                <p>Monday - Friday Evening: 7:00pm</p>
                                <p>Tuesday - Friday Morning: 9:30am</p> */}
                            </div>
                        </div>
                    </div>

                    <p className="center-text small-text padding-top-50">*If the live stream is not currently available, try refreshing the YouTube or Facebook page closer to the streaming schedule.</p>

                </div>

            </section>

            <section className="light-bg">
                <div className="container-narrow padding-50-20 border-top-medium">
                    <h3 className="center-text">Can't wait for the next scheduled service?</h3>
                    <Link to="/messages" className="btn btn-dark-blue center margin-top-20">LISTEN NOW</Link>
                </div>
            </section>
        </>
    );
}

export default Livestream;
