import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

const BibleReading = () => {
    const [plan, setPlan] = useState([]);
    useEffect(() => {

        fetch("/plan.json")
            .then((response) => response.json())
            .then((data) => {
                setPlan(data.filter(todayReading => todayReading.id === getDay()));
            })
    }, []);

    useEffect(() => {
        topFunction();
    });

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    const getDay = () => {
        var now = new Date();
        var start = new Date(now.getFullYear(), 0, 0);
        var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
        var oneDay = 1000 * 60 * 60 * 24;
        var day = Math.floor(diff / oneDay);
        return day;
    }

    // const formatDate = (date) => {
    //     const monthNames = ["January", "February", "March", "April", "May", "June",
    //         "July", "August", "September", "October", "November", "December"
    //     ];

    //     const d = new Date(date + "T00:00:00");
    //     return monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
    // }

    return (
        <>
            <div id="dark-blue-bg">
                <div className="container page-hero" id="reading-hero">
                    <div className="page-hero-message">
                        <h1>Together In The Word</h1>
                    </div>
                </div>
            </div>

            <section className="light-bg">
                <div className="container-ultra-narrow padding-50-20 padding-20-s">
                    <p className="center-text"><b>Read along with Gateway</b></p>
                    {plan.map((reading) => (
                        <div key={reading.id}>
                            <h2 className="center-text">Today's reading:<br/><b>{reading.title}</b></h2>
                            <br/><hr/><br/>
                    <p className="center-text">Don't have your Bible with you?</p>
                    <a href={"https://www.kingjamesbibleonline.org/" + reading.url} className="btn btn-large center btn-dark-blue margin-top-20" rel="noreferrer" target="_blank">Read {reading.title} Online</a>
                        </div>
                    ))}
                    <a href="https://www.blueletterbible.org/assets/pdf/dbrp/1Yr_CanonicalPlan.pdf" className="btn btn-large center black" rel="noreferrer" target="_blank">Download the PDF Guide &gt;</a>
                </div>
            </section>
        </>
    );
}

export default BibleReading;
