import React from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Beliefs from './components/pages/Beliefs';
// import Pastor from './components/pages/Pastor';
import ChurchMessages from './components/pages/ChurchMessages';
import JubileeMessages from './components/pages/JubileeMessages';
import GatewayRadio from './components/pages/GatewayRadio';
import BibleReading from './components/pages/BibleReading';
import Livestream from './components/pages/Livestream';
import SundayMessages from './components/pages/SundayMessages';
import LocalMinistries from './components/pages/LocalMinistries';
import Missions from './components/pages/Missions';
import Contact from './components/pages/Contact';
import AudioPlayer from './components/pages/AudioPlayer';
import VideoPlayer from './components/pages/VideoPlayer';
import Welcome from './components/pages/Welcome';
import Thanks from './components/pages/Thanks';
import NotFound from './components/pages/NotFound';

const App = () => {
  return (
    <>
      <Router>

        <Header />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/what-we-believe' component={Beliefs} />
          {/* <Route path='/our-pastor' component={Pastor} /> */}
          <Route path='/messages' component={ChurchMessages} />
          <Route path='/gateway-radio' component={GatewayRadio} />
          <Route path='/in-the-word' component={BibleReading} />
          <Route path='/live' component={Livestream} />
          <Route path='/local-ministries' component={LocalMinistries} />
          <Route path='/video' component={SundayMessages} />
          <Route path='/jubilee' component={JubileeMessages} />
          <Route path='/missions' component={Missions} />
          <Route path='/contact' component={Contact} />
          <Route path='/sermon-audio' component={AudioPlayer} />
          <Route path='/video-player' component={VideoPlayer} />
          <Route path='/welcome' component={Welcome} />
          <Route path='/thanks' component={Thanks} />
          <Route path='/info-thanks' component={Thanks} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;