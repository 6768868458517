import React, { useEffect } from 'react';

const Beliefs = () => {
    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        <>
            <div id="dark-blue-bg">
                <div className="container page-hero" id="believe-hero">
                    <div className="page-hero-message">
                        <h1>What We Believe</h1>
                    </div>
                </div>
            </div>

            <section className="light-bg">
                <div className="container-narrow padding-50-20">
                    <div className="belief">
                        <h3>Scriptures</h3>
                        <div>
                            We believe that the Holy Bible was written by men divinely inspired, and is a perfect treasure of heavenly instruction that it has God for its author, salvation for its end, and truth for its matter; that it reveals the principles by which God will judge us; and therefore is, and will remain to the end of the world, the true center of Christian union, and the supreme standard by which all human conduct, creeds, and religious opinions should be tried. II Timothy 3:14-17; II Peter 1:21; This church accepts the King James Version as the Word of God, and uses it exclusively.
                </div>
                    </div>

                    <div className="belief">
                        <h3>Trinity</h3>
                        <div>
                            There is one and only one living and true God, intelligent, spiritual, and personal being, the creator preserver, and ruler of the universe, infinite in Holiness, and all other perfection, to whom we owe the highest love, reverence, and obedience. He is revealed to us as Father, Son, and Holy Ghost, each with distinct personal attributes, but without division of nature, essence, or being. (Matthew 28:19; John 10:30; II Corinthians 13:14; I John 5:7)
                </div>
                    </div>

                    <div className="belief">
                        <h3>Fall of Man</h3>
                        <div>
                            We believe man was created by God, in His image and likeness in a state of innocence. We believe that man fell through a direct and voluntary act of sin and disobedience towards God. Man therefore now has a sin nature and all men are sinners by choice. Mankind is now under the condemnation of God and can only be free of this condemnation by accepting Jesus Christ as their personal Saviour. (Genesis 1:27; Genesis 3:6-24; Isaiah 53:6; Romans 5:19; Ephesians 2:1-3)
                </div>
                    </div>

                    <div className="belief">
                        <h3>Salvation</h3>
                        <div>
                            We believe that salvation is by grace through faith in the finished work of Christ and that the personal works of any person has nothing whatsoever to do with salvation. Our salvation was purchased by Jesus Christ on the cross of Calvary. Only His blood is able to be an atonement for man's sin. He is our substitute, Jesus the Just, dying for man, the unjust. Salvation is a free gift of God, and it is given to all who put their faith and trust in Christ for the forgiveness of sin. Salvation is an eternal gift, not earned by works, nor are we kept by our works. Salvation is wholly a work of God. Salvation is not a process, but an instantaneous act of God upon repentant man. When we trust in Christ we are regenerated by the Holy Spirit, sealed, and kept eternally secure by God until the day of redemption. (John 3:16; Romans 1:16; Romans 5:1; Romans 10:9-17; Ephesians 2:8,9; Hebrews 7:25)
                </div>
                    </div>

                    <div className="belief">
                        <h3>Justification</h3>
                        <div>
                            We believe that justification is the pardon and remission from sin in a person's life. Through justification, we have the condemnation and guilt of sin removed. Through the blood of Christ, God now sees us as righteous through the righteousness of Christ, which is imputed to all who are born again. Justification now puts man in a right place before a Holy God, not through our righteousness, but through the righteousness of Christ. (Isaiah 53:4-5; Romans 5:1; Romans 5:9)
                </div>
                    </div>

                    <div className="belief">
                        <h3>Sanctification</h3>
                        <div>
                            Positionally every believer in Christ has been set apart by God. Practical sanctification is the process of setting apart, or the state of being set apart, for God and from the world. The primary root meaning of the word is "separation." This separation, or setting apart, includes also dedication. Thus sanctification in its general sense is the separation and dedication of a person or object to and for God, to belong wholly to Him and to be used for His glory (I Corinthians 1:2; Romans 12:1-3). Sanctification is not the baptism of the Holy Spirit. The baptism of the Holy Spirit refers to that event which took place at salvation whereby individual believers are placed into the "Body" of Christ (1 Corinthians 12:13). Sanctification is not perfectionism or the eradication of the Adamic nature according to 1 John 2:1,2 the believer is instructed not to sin, however if we do sin we have an advocate with the Father. This is a divine admission to the possibility of sin in the life of the believer. Sin breaks fellowship not salvation, fellowship is restored when sin is confessed and repented of (1 John 1:8-10; I John 1:1-2).
                </div>
                    </div>

                    <div className="belief">
                        <h3>Perseverance</h3>
                        <div>
                            All real believers endure to the end. Their continuing in well doing is the mark which distinguishes them from mere professors, and they are kept by the power of God through faith unto salvation. John 8:31; John 10:27-30; Philippians 1:6; I John 2:19,20.
                </div>
                    </div>

                    <div className="belief">
                        <h3>Eternal State</h3>
                        <div>
                            We believe that after this life there is an eternal destination for both the saved and the lost. When a saved person dies they go immediately into the presence of God, there to live in His presence forever. When a lost person dies they go to hell, the place prepared for the devil and his angels, for all eternity. While heaven is a place of joy and peace, and a place without sin, hell is a place of eternal and literal suffering. Heaven is a place prepared by God for His children. Hell is a place where God is not found and is filled with literal fire, thirst, darkness, hatred, and horror, which will never end. (Mark 9:43-48; Luke 16:19-31; Revelation 14:11; Revelation 20:10,15; II Corinthians 5:8; Revelation 21:4,27; Revelation 22:1-5)
                </div>
                    </div>

                    <div className="belief">
                        <h3>Church</h3>
                        <div>
                            A Church of Jesus Christ is a congregation of scripturally baptized believers, united together in a covenant of faith and fellowship of the Word of God. The church is to observe the ordinance of Christ, obey the great commission and instruct believers. (Acts 2:41,42; I Corinthians 11:23-32; Matthew 28:19-20; Hebrews 10:25). Jesus Christ is the head of the Church and we are under His leadership in all matters. The Pastor is the undershepherd of the local Church, and must give an account to Jesus Christ. (Hebrews 13:17; 1 Peter 5:1-4)
                </div>
                    </div>

                    <div className="belief">
                        <h3>Baptism</h3>
                        <div>
                            We believe that Baptism shall be for believers only, according to scripture. The only mode of Baptism is immersion. (Acts 8:36-39; Romans 6:4; Acts 2:41,42).
                </div>
                    </div>

                    <div className="belief">
                        <h3>Resurrection</h3>
                        <div>
                            The scriptures teach that Jesus bodily rose from the grave. He appeared on several occasions to His disciples and manifested His Lordship as Jesus Christ. He now exists in His glorified body at God's right hand. (Matthew 28:6; John 20:26-29; I Corinthians 15:1-58; Hebrews 10:12) The Bible teaches us that all the saved will one day be resurrected with a glorified body. (1 Corinthians 15:51-57; 1 Thessalonians 4:13-18).
                </div>
                    </div>

                    <div className="belief">
                        <h3>Person and Work of the Holy Spirit</h3>
                        <div>
                            We believe that the Holy Spirit is the third person of the Holy Trinity, who convicts the world of sin, of righteousness, and of judgement, and that He regenerates, indwells, enlightens, and empowers the truly saved. John 16:7,8; I Corinthians 3:16; 6:19; Ephesians 1:13; I John 2:27
                </div>
                    </div>

                    <div className="belief">
                        <h3>Return of The Lord</h3>
                        <div>
                            We believe in the Omniscience of God and that He has given to us in His Word prophetic accounts of future events. We believe that the next great event on God's calendar is the Rapture of the Church. This is the coming of Christ for His Bride the Church. At this time, Christ will come in the air and the dead in Christ shall rise first, then those who are born again by the blood of Christ will rise to meet Jesus in the air. We will then go to the Judgement Seat of Christ to give an account for the deeds done in the body. We will not be judged for our salvation because that will have already been settled. On earth the Tribulation will begin, as God pours out His wrath upon the earth. The Church will not go through any part of the Tribulation because the Church will be in heaven with Jesus. After seven years of Tribulation, Jesus will come to earth and will rule and reign from the throne of David for a thousand years. (John 14:1-3; Titus 2:13; Revelation 19:11-20:6; Revelation 21:7,12,20)
                </div>
                    </div>

                    <div className="belief">
                        <h3>Evangelism and Missions</h3>
                        <div>
                            It is the duty of every Christian man and woman, and the duty of every Church of Jesus Christ to seek to extend the Gospel to the ends of the earth. The new birth of man's spirit by God's Holy Spirit means the birth of love for others. Missionary effort on the part of all rests thus upon a spiritual necessity of the regenerate life. It is also expressly and repeatedly commanded in the teachings of Christ. It is the duty of every child of God to seek constantly to win the lost to Christ by personal effort and by all other methods sanctioned by the Gospel of Christ. (Matthew 22:8-10; 28:19-20; Mark 16:15; Luke 24:46-49; Acts 1:8)
                </div>
                    </div>

                    <div className="belief">
                        <h3>Separation from the World</h3>
                        <div>
                            Since being truly born again, - saved through the redemptive blood of Jesus Christ - knowing the body is the "temple of the Holy Ghost", we believe that all who are saved are called into a life of separation from worldly and sinful practices of this worldly generation. (Romans 12:1,2; James 4:4-7; I John 2:15-17; I Corinthians 6:19-20; II Corinthians 6:14-18).
                </div>
                    </div>

                    <div className="belief">
                        <h3>Ordinances</h3>
                        <div>
                            SECTION I - BAPTISM<br />
                                We believe that there are only two ordinances given in Scripture that we are to follow today. These two ordinances are baptism, and the Lord's Supper. Baptism is by immersion in water of a believer, in the name of the Father, and of the Son, and of the Holy Ghost. Baptism has nothing to do with a person's salvation, that is settled through the blood of Christ, when a person puts their faith and trust in Jesus. Baptism is a picture of the Death, Burial, and Resurrection of Christ. It is the answer of a good conscience toward God. It is an outward picture of an inward work. Baptism is a pre-requisite to the privileges of church membership and to the Lord's Supper. (Romans 6:3-4; Acts 8:36-38)<br /><br />
                                SECTION II - THE LORD'S SUPPER<br />The Lord's Supper is a commemoration of the death of Christ. The bread reminds us of His body, which was crucified on the cross, and the grape juice reminds us of the blood of Christ, which was given for our sins. The bread and the juice are symbols of what Christ has done for us, they are not the body of Christ nor do they become the body of Christ. Christians must always examine their lives and seek forgiveness for sin before partaking of the Lord's Supper. (I Corinthians 11:27-32)
                </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Beliefs;
