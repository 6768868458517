import React, { useState, useEffect } from 'react';
// import FacebookShare from '../FacebookShare';
import { Link } from 'react-router-dom';
import { MdSearch } from "react-icons/md";
// import Facebook from '../../images/facebook-share-btn.gif';

const ChurchMessages = () => {
    const [messages, setMessage] = useState([]);
    const [q, setQ] = useState("");

    useEffect(() => {
        fetch("https://www.gatewayjubilee.com/api/messages/")
            .then((response) => response.json())
            .then((data) => {
                setMessage(data);
            })
    }, []);

    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    const formatDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = new Date(date + "T00:00:00");
        return monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
    }

    function search(rows) {
        return rows.filter(row => row.speaker.toLowerCase().indexOf(q.toLowerCase()) > -1 || row.title.toLowerCase().indexOf(q.toLowerCase()) > -1);
    }

    return (
        <>
            <div id="dark-blue-bg">
                <div className="container page-hero" id="messages-hero">
                    <div className="page-hero-message">
                        <h1>Church Messages</h1>
                    </div>
                </div>
            </div>

            <section className="light-bg">
                <div className="container-narrow padding-50-20 padding-20-s">
                    <div className="message-search">
                        <form id="search-form" method="POST">
                            <MdSearch className="search-icon" />
                            <input type="text" value={q} className="form-control" placeholder="search by title or preacher" onChange={(e) => setQ(e.target.value)} />
                        </form>
                    </div>

                    <div className="flex flex-gap">
                        {search(messages).slice(0, 100).map((message) => (
                            <div className="width-30-lg width-50-md message-block" key={message.id}>
                                <div className="message-info">
                                    <div className="message-title" id="long">{message.title}</div>
                                    <div className="message-servicedate">
                                        <span className="message-service">{message.scodemsg}</span>
                                        <span className="message-date">{formatDate(message.msgdate)}</span>
                                    </div>
                                </div>
                                <div className="preacher">{message.speaker}</div>
                                <div className="message-links">
                                    <Link to={"/sermon-audio/?id=" + message.id} className="message-link">LISTEN NOW</Link>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
        </>
    );
}

export default ChurchMessages;
