import React, { useState, useEffect } from 'react';

const Missions = () => {
    const [missionaries, setMissionary] = useState([]);

    useEffect(() => {
        fetch("https://www.gatewayjubilee.com/api/missionaries/")
            .then((response) => response.json())
            .then((data) => {
                setMissionary(data);
            })
    }, []);

    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        <>
            <div id="dark-blue-bg">
                <div className="container page-hero" id="missions-hero">
                    <div className="page-hero-message">
                        <h1>Missionaries</h1>
                    </div>
                </div>
            </div>

            <section className="light-bg">
                <div className="container-narrow padding-50-20 padding-20-s">

                    <div className="flex flex-gap">
                        {missionaries.map((missionary) => (
                            <div className="width-30 width-50-md missionary-block padding-20" key={missionary.id}>
                                <div className="missionary-title" id="long">{missionary.fname} {missionary.lname}</div>
                                <div>{missionary.field}</div>
                            </div>
                        ))}

                    </div>

                </div>
            </section>
        </>
    );
}

export default Missions;
