import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const VideoPlayer = (props) => {
    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);
    const id = getLastItem(window.location.pathname);

    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        <>
            <div>
                <div className="single-message-info max-width-800 center">
                    <div className="embed-video-container">
                        <iframe src={"https://www.youtube.com/embed/" + id + "?rel=0&autoplay=1"} frameBorder="0" title="Gateway Video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="embed-video"></iframe>
                    </div>
                    <Link to="/messages" className="btn btn-large btn-dark-blue center margin-top-20" >BROWSE WEEKLY MESSAGES</Link>
                    <Link to="/jubilee" className="btn btn-large btn-dark-blue center margin-top-20" >BROWSE JUBILEE MESSAGES</Link>
                </div>
            </div>

        </>
    );
}

export default VideoPlayer;