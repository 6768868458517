import React from 'react';

const NotFound = () => {
    return (
        <>
            <div className="container-narrow padding-100-20">
                <h1 className="padding-top-75 center-text">Sorry, we could not find that page.</h1>
            </div>
        </>
    );
}

export default NotFound;
