import React from 'react';

const Welcome = () => {
    return (
        <>
            <div id="dark-blue-bg">
                <div className="container page-hero" id="welcome-hero"></div>
            </div>

            <section className="light-bg">
                <div className="container-ultra-narrow padding-50-20">
                    <h2 className="center-text">Thank you for stopping&nbsp;by!</h2>
                    <div className="padding-top-50">
                        <p>We pray the Lord Jesus Christ has spoken to your heart and you have listened to His call. The Bible says that <b><i>you are fearfully and wonderfully made</i></b>. Sin separated us from God, but God took on the form of his creation to die on the cross and redeem us to himself. <b>Please take a minute to share some information about yourself.</b></p>
                    </div>
                </div>

                <div className="container-500 padding-25-20-100">
                <form id="contactform" action="https://formsubmit.io/send/068e81ff-4e9d-40c2-83d4-c1103dc55ea8" method="POST">
                        <input name="_redirect" type="hidden" id="name" value="https://www.gatewayjubilee.com/thanks" />
                        <input type="text" name="name" className="welcome-form-control" placeholder="Your Name" required />
                        <input type="email" name="email" className="welcome-form-control" placeholder="Email Address" required />
                        <input type="tel" name="phone" className="welcome-form-control" placeholder="Phone Number" /><br/>
                        <input type="text" name="address" className="welcome-form-control" placeholder="Street Address" />
                        <input type="text" name="city" className="welcome-form-control" placeholder="City" />
                        <div className="flex-nowrap">
                            <input type="text" name="state" className="welcome-form-control" placeholder="State" />
                            <input type="text" name="zip" className="welcome-form-control" placeholder="Zip" />
                        </div><br/>
                        <div className="input-wrapper">
                            <div><b>Have you accepted Jesus Christ as your Savior?</b></div>
                            <label><input type="radio" name="saved" value="yes" required /> <span>Yes</span></label>
                            <span className="white">&nbsp;&nbsp;&nbsp;</span>
                            <label><input type="radio" name="saved" value="no" required /> <span>No</span></label>
                        </div>
                        <div className="input-wrapper">
                            <div><b>Would you like someone from the church to contact you?</b></div>
                            <label><input type="radio" name="contact" value="yes" required /> <span>Yes</span></label>
                            <span className="white">&nbsp;&nbsp;&nbsp;</span>
                            <label><input type="radio" name="contact" value="no" required /> <span>No</span></label>
                        </div><br/>
                        <textarea placeholder="Please, tell us a little bit about yourself." className="welcome-form-control" name="message" required></textarea>
                        <input type="hidden" name="_next" value="https://gatewayjubilee.com" />
                        <input type="hidden" name="_subject" value="New submission from tract" />
                        <input type="text" name="_honey" className="hidden" />
                        <input type="hidden" name="_captcha" value="false" />

                        <input className="hidden" name="_formsubmit_id" type="text" />

                        <input className="btn btn-dark-blue center margin-top-20" value="SEND" type="submit" />
                    </form>
                </div>
            </section>

            
        </>
    );
}

export default Welcome;
