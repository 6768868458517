import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import homeBible from '../../images/home-bible.jpg';
import homeMessages from '../../images/message-list.jpg';
import homeLive from '../../images/live-home.png';
import jubileeHome from '../../images/jubilee-home.jpg';

const Home = () => {
    const [plan, setPlan] = useState([]);
    useEffect(() => {

        fetch("/plan.json")
            .then((response) => response.json())
            .then((data) => {
                setPlan(data.filter(todayReading => todayReading.id === getDay()));
            })
    }, []);

    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    const getDay = () => {
        var now = new Date();
        var start = new Date(now.getFullYear(), 0, 0);
        var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
        var oneDay = 1000 * 60 * 60 * 24;
        var day = Math.floor(diff / oneDay);
        return day;
    }

    return (
        <>
            <div id="dark-blue-bg">
                <div id="hero" className="container">
                    <div id="hero-message">
                        <h1>Welcome To Gateway Jubilee</h1>
                        <p>ministry of Gateway Baptist Church</p>
                    </div>
                </div>
            </div>

            <section className="light-bg">
                <div className="container-narrow padding-50-20">
                    <h2 className="center-text padding-bottom-50">Preaching and teaching from the Word of God</h2>
                    <div className="flex flex-center">
                        <div className="width-50 margin-bottom-20-s">
                            <img src={homeBible} alt="student of the Bible" />
                        </div>
                        <div className="width-50 flex flex-center flex-column">
                            <div>
                                <p className="padding-side-20 max-width-500 center center-text">Are you looking for a place where you can worship and serve as part of a local church family? Gateway Baptist Church provides Bible-based preaching and geniune fellowship.</p>
                                <Link to='/what-we-believe' className="btn btn-dark-blue center margin-top-20">LEARN MORE</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="light-bg">
                <div className="container-narrow padding-side-20 padding-bottom-50 padding-top-50 border-top-light">

                <h2 className="center-text">Read along with us in God's Word</h2><br/>
                    {plan.map((reading) => (
                        <div key={reading.id}>
                    <h4 className="center-text">Today's reading:<br/><b>{reading.title}</b></h4>
                    <Link to="/in-the-word" className="btn center btn-dark-blue margin-top-20">LEARN MORE</Link>
                        </div>
                    ))}
                </div>
            </section>

            <section className="medium-blue-bg">
                <div className="container-narrow padding-50-20">
                    <h2 className="center-text padding-bottom-50 white">Encouraging messages to help you navigate your week</h2>
                    <div className="flex flex-center flex-reverse">
                        <div className="width-50 border-white margin-bottom-20-s">
                            <img src={homeMessages} alt="Bible preaching" />
                        </div>
                        <div className="width-50 flex flex-center flex-column">
                            <div>
                                <p className="padding-side-20 max-width-500 center center-text white">Search our virtually endless supply of messages from Pastor Tim Ramsey, Davy Shelton, Ray&nbsp;K.&nbsp;Stuart and many others. Over 2,000 messages and growing!</p>
                                <Link to="/messages" className="btn btn-dark-blue center margin-top-20">LISTEN NOW</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="light-bg">
                <div className="container-narrow padding-50-20">
                    <h2 className="center-text padding-bottom-50">Streaming live on social&nbsp;media</h2>
                    <div className="flex flex-center">
                        <div className="width-50 margin-bottom-20-s">
                            <img src={homeLive} alt="live on youtube and facebook" />
                        </div>
                        <div className="width-50 flex flex-center flex-column">
                            <div>
                                <p className="padding-side-20 max-width-500 center center-text">We would much rather worship together in person, but if you are unable to join us, we hope you can worship with us online. Watch us here or on your favorite social media.</p>
                                <Link to="/live" className="btn btn-dark-blue center margin-top-20">LEARN MORE</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="dark-blue-bg">
                <div className="container page-hero" id="hero2"></div>
            </div>

            <section className="light-bg">
                <div className="container-narrow padding-50-20">
                    <h2 className="center-text padding-bottom-50">Start your year with&nbsp;Jubilee</h2>
                    <div className="flex flex-center flex-reverse">
                        <div className="width-50 margin-bottom-20-s">
                            <img src={jubileeHome} alt="student of the Bible" />
                        </div>
                        <div className="width-50 flex flex-center flex-column">
                            <div>
                                <p className="padding-side-20 max-width-500 center center-text">Jubilee is a celebration of what the Lord has done and an encouragement to continue pressing toward the mark. We hope you will join us online for a celebration in worship.</p>
                                <Link to="/jubilee" className="btn btn-dark-blue center margin-top-20">LEARN MORE</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
