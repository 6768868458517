import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Contact = () => {
    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        <>
            <div id="dark-blue-bg">
                <div className="container page-hero" id="contact-hero">
                    <div className="page-hero-message">
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </div>

            <section className="medium-blue-bg">
                <div className="container-ultra-narrow padding-100-20">
                    <h2 className="white">Thanks for stopping by! We pray the messages and content have been a blessing to you.</h2>
                    <div className="padding-top-50">
                        <div>
                            <h3 className="padding-bottom-20 white">Weekly Service Schedule:</h3>
                            <p className="white">Sunday School: 10:00am</p>
                            <p className="white">Sunday Morning Worship: 11:00am</p>
                            <p className="white">Sunday Evening Worship: 6:30pm</p>
                            <p className="white">Wednesday Prayer Meeting: 7:00pm</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-narrow padding-50-20">
                    <h3 className="center-text">New To Gateway</h3>
                    <Link to='/welcome' className="btn btn-large btn-dark-blue center margin-top-20">WELCOME PAGE</Link>
                </div>
            </section>

            <section className="dark-blue-bg">
                <div className="container-narrow padding-50-20">
                <h3 className="center-text white">General questions</h3>
                    <a href="mailto:office@gatewaybaptist.sc" className="btn btn-large btn-light-blue center margin-top-20">CONTACT OUR OFFICE</a>
                </div>
            </section>

            <section>
                <div className="container-narrow padding-50-20">
                    <h3 className="center-text">Website Issues</h3>
                    <a href="mailto:admin@gatewayjubilee.com" className="btn btn-large btn-dark-blue center margin-top-20">CONTACT SUPPORT</a>
                </div>
            </section>

            <section>
                <div className="map-view">
                    <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3266.507987690866!2d-81.99306898475854!3d35.04403668034772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8857712c1fcace57%3A0xad8c98988a2a0f0f!2sGateway%20Baptist%20Church!5e0!3m2!1sen!2sus!4v1605889269053!5m2!1sen!2sus" frameBorder="0" allowFullScreen="" tabIndex="0"></iframe>
                </div>
                <div className="map-view-mobile">
                    <a href="https://maps.google.com/maps?ll=35.044037,-81.99088&z=16&t=m&hl=en&gl=US&mapclient=embed&daddr=Gateway%20Baptist%20Church%201600%20Old%20Furnace%20Rd%20Boiling%20Springs%2C%20SC%2029316@35.0440367,-81.9908803">
                        <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3266.507987690866!2d-81.99306898475854!3d35.04403668034772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8857712c1fcace57%3A0xad8c98988a2a0f0f!2sGateway%20Baptist%20Church!5e0!3m2!1sen!2sus!4v1605889269053!5m2!1sen!2sus" frameBorder="0" allowFullScreen="" tabIndex="0"></iframe>
                    </a>
                </div>
            </section>
        </>
    );
}

export default Contact;
