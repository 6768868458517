import React, { useState, useEffect } from 'react';
// import FacebookShare from '../FacebookShare';
import { Link } from 'react-router-dom';
import { MdSearch } from "react-icons/md";
// import Facebook from '../../images/facebook-share-btn.gif';

const JubileeMessages = () => {
    const [messages, setMessages] = useState([]);
    const [selection, setSelection] = useState(true);
    const [q, setQ] = useState("");
    const d = new Date();
    const year = d.getFullYear();

    useEffect(() => {
        fetch("https://www.gatewayjubilee.com/api/jubilee/")
            .then((response) => response.json())
            .then((data) => {
                setMessages(data);
            })
    }, []);

    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function search(rows) {
        return rows.filter(row => row.speaker.toLowerCase().indexOf(q.toLowerCase()) > -1 || row.title.toLowerCase().indexOf(q.toLowerCase()) > -1 || row.series.toLowerCase().indexOf(q.toLowerCase()) > -1);
    }

    function current(rows) {
        return rows.filter(row => row.series === "jub" + year.toString().slice(2, 4));
    }

    return (
        <>
            <div id="dark-blue-bg">
                <div className="container page-hero" id="jubilee-hero">
                    <div className="page-hero-message">
                        <h1>Winter Jubilee</h1>
                    </div>
                </div>
            </div>

            {/* <section className="alert-bg">
                <div className="container-narrow padding-20 padding-20-s">
                    <div className="notice center-text">
                        We will live stream Winter Jubilee for all visitors and limit occupancy to members of Gateway Baptist Church. We hope you will <b><Link to="/live" className="black">join us online</Link></b> and pray for this year's services with the same fervency as you would if you were joining us in person. We will miss you. We trust and look forward to worshipping together next year. We hope you will also take advantage of the links below and enjoy messages from past Jubilee meetings.
                </div>
                </div>
            </section> */}

            <section className="light-bg">
                <div className="container-narrow padding-50-20 padding-20-s">

                    <div className="jubilee-search">
                        <div id="search-form">
                            <MdSearch className="search-icon" />
                            <input type="text" value={q} className="form-control" placeholder="search by title or preacher" onChange={(e) => setQ(e.target.value)} />
                        </div>

                        <div className="selection-toggle">
                            <button className={selection ? "active" : "inactive"} onClick={() => setSelection(true)}>Jubilee {year}</button>
                            <button className={selection ? "inactive" : "active"} onClick={() => setSelection(false)}>Show All</button>
                        </div>

                    </div>

                    <div className="flex flex-gap">
                        {search(selection ? current(messages) : messages).map((message) => (
                            <div className="width-30-lg width-50-md message-block" key={message.id}>
                                <div className="jubilee-info">
                                    <div className="message-title" id="long">{message.title}</div>
                                </div>
                                <div className="preacher">{message.speaker}</div>
                                <div className="message-links">
                                    <Link to={"/sermon-audio/?id=" + message.id} className="message-link">LISTEN NOW</Link>
                                    {message.youtube !== '' ? <Link to={"/video-player/" + message.youtube} className="message-link">FULL SERVICE</Link> : ""}
                                </div>
                                <div className="share-section">
                                    <div className="jub-year">{message.series}</div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
        </>
    );
}

export default JubileeMessages;
