import React, { useEffect } from 'react';

import youthGroup from '../../images/youth-group.jpg';
import outreach from '../../images/outreach.jpg';

const LocalMinistries = () => {
    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        <>
            <div id="dark-blue-bg">
                <div className="container page-hero" id="ministries-hero">
                    <div className="page-hero-message">
                        <h1>Local Ministries</h1>
                    </div>
                </div>
            </div>

            {/* <section className="light-bg">
                <div className="container-narrow padding-50-20">
                    <h2 className="center-text padding-bottom-50">Sunday School for all ages</h2>
                    <div className="flex flex-center">
                        <div className="image-wrap width-50 border-white margin-bottom-20-s">
                            <img src={placeholder} alt="sunday school class" />
                        </div>
                        <div className="width-50 flex flex-center flex-column">
                            <div>
                                <p className="padding-side-20 max-width-500 center center-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius distinctio numquam aut recusandae non. Officia quam eaque, blanditiis corrupti maiores veniam dolorem obcaecati accusamus alias delectus cumque, temporibus autem sed soluta.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="light-bg">
                <div className="container-narrow padding-50-20">
                    <h2 className="center-text padding-bottom-50">Youth & Teen Ministries</h2>
                    <div className="flex flex-center flex-reverse">
                        <div className="width-50 border-white margin-bottom-20-s">
                            <img src={youthGroup} alt="youth group" />
                        </div>
                        <div className="width-50 flex flex-center flex-column">
                            <div>
                                <p className="padding-side-20 max-width-500 center center-text">Youth breathe life into a church. Without young people, the future of any church is in question. We love our young people and encourage them to follow the Lord and live a life devoted to Him. Gateway Baptist Church is a great place for youth to connect.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="medium-blue-bg">
                <div className="container-narrow padding-50-20">
                    <h2 className="center-text padding-bottom-50 white">Community Outreach</h2>
                    <div className="flex flex-center">
                        <div className="width-50 border-white margin-bottom-20-s">
                            <img src={outreach} alt="visiting those new to the area" />
                        </div>
                        <div className="width-50 flex flex-center flex-column">
                            <div>
                                <p className="padding-side-20 max-width-500 center center-text white">Our goal is to reach every person in our area with the Gospel of Jesus Christ. If you do not attend church regularly, we invite you to visit Gateway Baptist Church.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LocalMinistries;
