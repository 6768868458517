import React from 'react';
import { Link } from 'react-router-dom';

const Thanks = () => {
    return (
        <>
            <section className="light-bg">
                <br /><br /><br /><br />
                <div className="container-ultra-narrow padding-50-20">
                    <div className="flex flex-center">

                        <div className="width-50 padding-bottom-20-s">
                            <h2 className="center-text margin-bottom-20 center-text">Thank you for the message!</h2>
                            <p className="center-text">Here are some messages to help you learn more about God and His Word.</p>
                            <Link to="/messages" className="btn btn-dark-blue center margin-top-20">LISTEN NOW</Link>
                        </div>

                    </div>

                </div>

            </section>
        </>
    );
}

export default Thanks;
