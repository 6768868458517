import React, { useState, useEffect } from 'react';
import livePlaceholder from '../../images/live-placeholder.jpg';

const SundayMessages = () => {
    const [messages, setMessages] = useState([]);
    const [player, setPlayer] = useState([]);
    const youtube = "https://www.youtube.com/embed/";
    const ytPrefs = "?rel=0&autoplay=1";

    useEffect(() => {
        fetch("https://www.gatewayjubilee.com/api/messages/")
            .then((response) => response.json())
            .then((data) => {
                setMessages(data);
            })
    }, []);

    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    const formatDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
        ];

        const d = new Date(date + "T00:00:00");
        return dayNames[d.getDay()] + ", " + monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
    }

    return (
        <>
            <div id="dark-blue-bg">
                <div className="container page-hero" id="sunday-hero">
                    <div className="page-hero-message">
                        <h1>Sunday Services</h1>
                    </div>
                </div>
            </div>

            <section className="light-bg">
                <div className="container-narrow padding-50-20 padding-50-0">
                    <div className="flex">
                        {messages.filter(message => message.youtube).slice(0, 1).map((message) => (
                            <div className="width-50" key={message.id}>
                                <div className="video-section">
                                    <h2 className="padding-bottom-20">Latest Messages</h2>
                                    <div className="embed-video-container">
                                        <iframe title="youtube" src={player.length === 0 ? youtube + message.youtube + ytPrefs : youtube + player.youtube + ytPrefs}></iframe>
                                    </div>
                                    <p className="video-title padding-top-10">{player.length === 0 ? message.title : player.title}</p>
                                    <p className="video-date">{player.length === 0 ? formatDate(message.msgdate) : formatDate(player.msgdate)}</p>
                                </div>
                            </div>
                        ))}

                        <div className="flex width-50 padding-side-20 padding-side-0-s padding-top-50-s overflow-scroll">
                            <div className="slide-container">
                                <h2 className="padding-bottom-20 hidden-s">&nbsp;</h2>
                                {messages.filter(message => message.youtube).slice(0, 10).map((message) => (

                                    <div className="flex padding-20 video-list" key={message.id}>
                                        <div className="flex flex-center each-video">
                                            <button className="video-list-btn width-33" onClick={() => {
                                                setPlayer(message);
                                            }}>
                                                <img src={livePlaceholder} alt="video placeholder" />
                                            </button>
                                            <div className="width-66 padding-side-20 padding-side-0-s">
                                                <p className="video-list-title">{message.title}</p>
                                                <p className="video-list-date">{formatDate(message.msgdate)}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default SundayMessages;
